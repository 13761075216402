import React from 'react';
import NavbarServices from './../NavbarServices';
import ServicesTextCard from './../ServicesTextCard';
import Banner from './../Banner';
import BorderYellow from './../BorderYellow';
import Video from './../Video';
import bannerImg from '../../assents/Img/yellow-services-background.png';
import video from '../../assents/Video/camera-video-web.webm';
import Footer from '../Footer';
import Navbar from '../Navbar';

function HighDefinition() {
  return (
    <>
      <Navbar linkTwo={{boxShadow: 'inset 500px 0 0 0 #0a1b2d', color: 'white', borderRadius: '0.6rem'}}/>
      <NavbarServices linkTwo={{boxShadow: 'inset 500px 0 0 0 #F8B234', color: 'white', borderRadius: '0.6rem', borderRight: '2px solid transparent'}}/>
      <Banner bannerImg={bannerImg} mainTitle="High-Definition Visual Inspection"/>
      <BorderYellow top={{top: "56vh"}}/>
      <ServicesTextCard 
        firstText="Coke Drum Inspection: Remote Visual Inspection"
        title=""
        mainPOne="A critical part of the inspection because the resulting visual images inform the likelihood of failure
        analysis, VIAAC uses a remotely operated high-definition camera mounted to the drill stem to take
        high-quality, location-indexed images that are immediately assessed and categorized. Depending
        on site requirements 300 to 800 images may be captured for a more complete understanding of
        drum condition."
        mainPTwo='Images from the coke drum’s internal visual inspection are displayed in VesselViewer™ and
        supplied to the refinery before VIAAC staff depart.'
      />
      <Video video={video} videoWidth="80%"/>
      <Footer/>
    </>
  );
}

export default HighDefinition;
