import React from 'react';
import './HomePage.css';


function SubtitleHome(props) {

  return (
    <>
      {/* <button className='login_btn'> Login <img src={log} alt="Logo" className='log'/></button> */}
      <h4 className='subtitle'>Vessel Inspection and Assessment Corp</h4>
    </>
  );
  
}


export default SubtitleHome;