import React from 'react';
import './Form.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import icon from '../assents/Img/logo.png'

class Forms extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        company:'',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        message: '',
        flag : true
      }
    }

    handleSubmit(event){ 

        event.preventDefault();
        fetch('https://viaac.de/mail', {
        method: 'POST',
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state)
        }).then(
            response => {
                if(response.status===200){
                    console.log('Message sent');
                    // alert("Message Sent.");
                    this.resetForm()
                    this.setState({flag: !this.state.flag})
                }
            }).catch(err=>{
                console.error('Error al enviar correo',err);
                alert("Message failed to send.")
            })

    };
    resetForm(){
        this.setState({company:'',firstName: '',lastName: '',email: '',mobile: '',message: ''})
    }
    render() {
        var classes = this.state.flag === true ? 'dont-show' : 'confirmation-container'

      return(
        <>  
            <div className='form-card'>
                <div className='form-max-width'>
                    <div className='form-container'>
                        <Form className='form' onSubmit={this.handleSubmit.bind(this)} method="POST">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Company:</Form.Label>
                                <Form.Control type="text" value={this.state.company} onChange={this.onCompanyChange.bind(this)} required/>
                            </Form.Group>
                            <Row>
                                <Form.Group className="col-sm-12 col-md-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" controlId="formBasicPassword">
                                    <Form.Label>First Name:</Form.Label>
                                    <Form.Control type="text" value={this.state.firstName} onChange={this.onFirstNameChange.bind(this)} required/>
                                </Form.Group>

                                <Form.Group className="col-sm-12 col-md-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" controlId="formBasicPassword">
                                    <Form.Label>Last Name:</Form.Label>
                                    <Form.Control type="text" value={this.state.lastName} onChange={this.onLastNameChange.bind(this)}/>
                                </Form.Group>
                            </Row>

                            <Form.Group className="col-sm-12 col-md-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8" controlId="formBasicPassword">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} required/>
                            </Form.Group>
                            <Form.Group className="col-sm-12 col-md-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8" controlId="formBasicPassword">
                                <Form.Label>Mobile:</Form.Label>
                                <Form.Control type="tel" value={this.state.mobile} onChange={this.onMobileChange.bind(this)} required/>
                            </Form.Group>

                            <Form.Group className="col-sm-12 col-md-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message:</Form.Label>
                                <Form.Control as="textarea" rows={5} maxlength="400" value={this.state.message} onChange={this.onMessageChange.bind(this)}/>
                            </Form.Group>

                            <Button variant="primary" type="submit" className='btn-send'>
                                Send
                            </Button>
                            <Alert variant="danger" dismissible className='d-none mt-3'>
                                Message failed to send, check if I entered the fields correctly
                            </Alert>
                            <Alert variant="success" dismissible className='d-none mt-3'>                          
                                Message sent successfully
                            </Alert>
                        </Form>
                    </div>
                </div>
            </div>
            <div className={classes}>
                <div className='confirmation-light'>
                    <div className='confirmation-imagen'>
                        <span onClick={()=>this.setState({flag:true})} className='exitBtn'>&times;</span>
                        <img src={icon} alt="Home" className='img-confir'/>
                        <div className='fondo-dos'></div>
                        <div className='fondo-uno'> 
                            <h4>Thank you for contactting <br/> Vessel Inspection Corp</h4>
                            <p>Your inquiry has been received so we will get back to you very soon</p> 
                            <h1>VIAAC</h1>
                        </div>
                    </div>
                    <div className='yellow-confirmation'><span>VESSEL INSPECTION </span> AND ASSESSMENT CORP</div>
                </div>
            </div>
        </>
  ); 
}   
    onCompanyChange(event) {
        this.setState({company: event.target.value})
    }
    onFirstNameChange(event) {
        this.setState({firstName: event.target.value})
    }
    onLastNameChange(event) {
        this.setState({lastName: event.target.value})
    }
    onEmailChange(event) {
        this.setState({email: event.target.value})
    }
    onMobileChange(event) {
        this.setState({mobile: event.target.value})
    }
    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
}
export default Forms;