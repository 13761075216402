import React from 'react';
import ReactPlayer from 'react-player'
import './Video.css';

function Video(props) {

  return (
    <>
        <div className='main-video-container video-container'>
          {/* <ReactPlayer 
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            onContextMenu={e => e.preventDefault()}
            url={props.video} 
            width={props.videoWidth} 
            height="auto" 
            muted="true" 
            playing="true" 
            controls="true" 
            loop="true"
            /> */}
            <video autoPlay loop controls controlsList="nodownload" oncontextmenu="return false;" width={props.videoWidth} height="auto" poster={props.postel}>
              <source src={props.video} type='video/mp4'/>
              <source src={props.videogg} type='video/ogg'/>
              <source src={props.videowebm} type='video/webm'/>
              <embed src={props.videoswf} type='application/x-shockwave-flash' width={props.videoWidth} height="auto"></embed>
            </video>
        </div>
    </>
  );
  
}


export default Video;