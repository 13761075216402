import React from 'react';
import './HomePage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../assents/Img/logo-homepage.png'


function InfoHome(props) {

  return (
    <>  
    <div className='info-home-card'>
        <div className='info-home-max-width'> 
            <Row>
                <Col sm={12} md={2} lg={2} xl={2} xxl={2} style={{textAlign:"center"}}>
                    <img src={logo} alt="Logo" className='logo-description-homepage'/>
                </Col>
                <Col sm={12} md={10} lg={10} xl={10} xxl={10} className="info">
                    <h1>The Importance of Regular Coke Drum Inspection</h1>
                    <p>
                        Delayed Coker operations produce severe thermal cycles that, over time, cause distortions, cracking, and other damage
                        to the drum’s shell. To ensure safe and profitable operation of the Delayed Coker Unit and allow efficient maintenance
                        planning, remotely operated 3D Laser Scans and Visual Inspection allow a refinery to locate and assess the damage
                        that accumulates over time. This avoids costly damage and production losses due to unpredicted outages.
                    <br/>
                    <br/>
                        Vessel Inspection and Assessment Corp. (VIAAC) specializes in capturing the internal profile of each coke drum
                        inspected and any change from the original design or from previous inspections. Utilizing a proprietary technique
                        based on strain, and derived from years of observations of coke drum damage and failures, to find and categorize
                        damage, VIAAC staff are able to make an immediate assessment of the likelihood of failure during an inspection.
                    <br/>
                    <br/>
                        An assessment of whether an inspected coke drum remains at or near its designed parameters, and the locations of
                        areas that may need further assessment, inspection or repair, is provided before our staff leaves site. The very precise
                        data set of each coke drum’s cylinder and cone is displayed using VIAAC&#39;s custom VesselViewer™ software to provide
                        comparisons and monitor changes over time. A written report, including graphs and tables so that the information is
                        readily understandable, is provided, and forms the basis for institutional understanding of each coke drum’s condition
                        including a preliminary analysis of the likelihood of failure.
                    </p>
                </Col>
            </Row>
        </div>
    </div>
         

    </>
  );
  
}


export default InfoHome;