import React from 'react';
import './HomePage.css';
import icon from '../assents/Img/idioma.png'


function Language(props) {

  return (
    <>
        <img src={icon} alt="Language" className='language'/>
    </>
  );
  
}


export default Language;