import React from 'react';
import NavbarServices from './../NavbarServices';
import ServicesTextCard from './../ServicesTextCard';
import Banner from './../Banner';
import BorderYellow from './../BorderYellow';
import Video from './../Video';
import bannerImg from '../../assents/Img/yellow-services-background.png';
import video from '../../assents/Video/skirt-video-web.webm';
import Footer from '../Footer';
import Navbar from '../Navbar';

function ExternalCoke() {
  return (
    <>
      <Navbar linkTwo={{boxShadow: 'inset 500px 0 0 0 #0a1b2d', color: 'white', borderRadius: '0.6rem'}}/>
      <NavbarServices linkThree={{boxShadow: 'inset 500px 0 0 0 #F8B234', color: 'white', borderRadius: '0.6rem', borderRight: '2px solid transparent'}}/>
      <Banner bannerImg={bannerImg} mainTitle="External Coke Drum Skirt Scans"/>
      <BorderYellow top={{top: "56vh"}}/>
      <ServicesTextCard 
        firstText="Coke Drum Skirt Scan – Documents the Condition of the Drum’s Skirt and Foundation"
        title=""
        mainPOne="Skirts are a known problem area for coke drum operators. Subject to the constantly varying temperatures and dimensions of an operating coke drum and a fixed foundation, stresses in the skirt are concentrated and can quickly cause significant damage including cracking and distortions. Laser mapping is able to determine if there are dimensional changes or distortions in the skirt and results can be analyzed as part of an Engineering Assessment. Note that insulation must be removed so the skirt profile can be scanned."
      />
      <Video video={video} videoWidth="100%"/>
      <Footer/>
    </>
  );
}

export default ExternalCoke;
