import React from 'react';
import NavbarServices from './../NavbarServices';
import ServicesTextCard from './../ServicesTextCard';
import Banner from './../Banner';
import BorderYellow from './../BorderYellow';
import Video from './../Video';
import bannerImg from '../../assents/Img/yellow-services-background.png';
import video from '../../assents/Video/laser-video-web.webm';
import videogg from '../../assents/Video/laser-video-web.ogg';
import videowebm from '../../assents/Video/laser-video-web.webm';
import videoswf from '../../assents/Video/laser-video-web.swf';
import Footer from '../Footer';
import Navbar from '../Navbar';

function Services() {
  return (
    <>
      <Navbar linkTwo={{boxShadow: 'inset 500px 0 0 0 #0a1b2d', color: 'white', borderRadius: '0.6rem'}}/>
      <NavbarServices linkOne={{boxShadow: 'inset 500px 0 0 0 #F8B234', color: 'white', borderRadius: '0.6rem', borderRight: '2px solid transparent'}} />
      <Banner bannerImg={bannerImg} mainTitle="3D Laser Scans of Coke Drum Internals"/>
      <BorderYellow top={{top: "56vh"}}/>
      <ServicesTextCard 
        firstText="Coke Drum Inspection: Remote 3-D Laser Mapping"
        title=""
        mainPOne="Coke drum laser scans are used to precisely determine the interior profile of the vessel cylinder
          and cone with multiple high-precision 3D laser scans while the drums are empty and clean. An
          accurate scan forms the basis for all further inspection and maintenance activities, so specialized
          techniques are used to minimize vibration using a low-profile adaptor mounted below the cutting
          tool or drill stem and custom software is used to process out drill stem motion."
      />
      <Video video={video} videogg={videogg} videowebm={videowebm} videoswf={videoswf} videoWidth="70%"/>
      <Footer/>
    </>
  );
}

export default Services;
