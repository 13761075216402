import React from 'react';
import Banner from '../Banner';
import BorderYellow from '../BorderYellow';
import Video from '../Video';
import bannerImg from '../../assents/Img/yellow-services-background.png';
import video from '../../assents/Video/software-web.webm';
import SoftwareDescription from '../SoftwareDescription';
import Footer from '../Footer';
import Navbar from '../Navbar';

function Software() {
  return (
    <>
      <Navbar linkThree={{boxShadow: 'inset 500px 0 0 0 #0a1b2d', color: 'white', borderRadius: '0.6rem'}}/>
      <Banner bannerImg={bannerImg} mainTitle="Custom Software Solutions"/>
      <BorderYellow top={{top: "47vh"}}/>
      <SoftwareDescription/>
      <Video video={video} videoWidth="80%"/>
      <Footer/>
    </>
  );
}

export default Software;
