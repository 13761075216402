import React from 'react';
import './SoftwareDescription.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import rightSofware from '../assents/Img/right-software.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SoftwareDescription(props) {

  return (
    <>
        <div className='description-card'>
          <div className='description-card-container container-sofware'>
              <Row className="row row-description">
                <Col sm={12} md={9} lg={8} xl={8} xxl={8} className='soft-text' >
                  <h1>Vessel Viewer™</h1>
                  <p>
                    Vessel Viewer™ is VIAAC’s custom software for viewing laser data and visual images captured
                    during an inspection. It is available in different versions and uses an innovative user interface to
                    display drum condition. Drum features including welds, repairs and overlay can be displayed, as
                    can located and categorized images from current and past inspections, including those captured
                    by other vendors. Sites can use their preferred orientation and weld naming conventions.
                  </p>
                </Col>
                <Col sm={12} md={3} lg={4} xl={4} xxl={4} className='col-software-img' >
                  <img src={rightSofware} alt="Logo" className='sofware-icon'/>
                </Col>
              </Row>
          </div>
        </div>
    </>
  );
  
}


export default SoftwareDescription;